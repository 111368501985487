/**
 * Created by Tomasz Kotlarek (ZICHER) on 18.01.2018.
 */

define('application/expand',[],function () {
  // TODO https://davidwalsh.name/add-rules-stylesheets

  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-expand").each(function () {
        var $this = $(this);
        var data = $.extend({}, $this.data("expand"));
        var $expandable;

        if (data.target === "__previous") {
          $expandable = $this.prev();
        } else if (data.target === "__next") {
          $expandable = $this.next();
        } else {
          $expandable = $document.find(data.target);
        }

        $this.on("click", function () {
          $this.toggleClass("active");
          $expandable.toggleClass("active");
        });
      });
    });

    return this;
  }
});
