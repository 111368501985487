/**
 * Created by Tomasz Kotlarek (ZICHER) on 28.08.2017.
 */

require.config({
  baseUrl: "/main/js",
  paths: {
    //"propertyParser": "../../bower_components/requirejs-plugins/src/propertyParser",
    //"async": "../../bower_components/requirejs-plugins/src/async",
    //"goog": "../../bower_components/requirejs-plugins/src/goog",
    "jquery": [
      "//cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min",
      "vendor/jquery/3.2.1/jquery.min"
    ],
    "owl-carousel": [
      "//cdnjs.cloudflare.com/ajax/libs/OwlCarousel2/2.2.1/owl.carousel.min",
      "vendor/owlcarousel/2.2.1/owl.carousel.min"
    ],
    "js-cookie": [
      "//cdnjs.cloudflare.com/ajax/libs/js-cookie/2.2.0/js.cookie.min",
      "vendor/jscookie/2.2.0/js.cookie.min"
    ],
    "dotdotdot": [
      "//cdnjs.cloudflare.com/ajax/libs/jQuery.dotdotdot/3.1.0/jquery.dotdotdot",
      "vendor/dotdotdot/3.1.0/jquery.dotdotdot"
    ],
    "picture-polyfill": "vendor/picturepolyfill/4.2.0/picturePolyfill.min"
  },
  shim: {
    "jquery": {
      exports: "jQuery"
    },
    "owl-carousel": ["jquery"]
  }
});

require(["application/main"]);
define("main", function(){});

