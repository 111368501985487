/**
 * Created by Tomasz Kotlarek (ZICHER) on 25.12.2017.
 */

define('application/main',[
  "application/options",
  "i18n!nls/text",
  "jquery",
  "picture-polyfill",
  "application/moveToFragment",
  "application/backToTop",
  "application/menu",
  "application/carousel",
  "application/map",
  "application/dotdotdot",
  "application/expand"
], function (appOptions,
             text,
             $,
             picturePolyfill,
             appMoveToFragment,
             appBackToTop,
             appMenu,
             appCarousel,
             appMap,
             appDotdotdot,
             appExpand) {
  var $window = $(window);
  var $document = $(document);
  var $html = $("html");
  var $body = $("body");

  var locale = $html.attr("lang");

  // TODO fix $document vs body vs dom issue with these functions

  appMoveToFragment($document);
  appBackToTop($document);
  appMenu($document);
  appCarousel($document);
  appMap($document);
  appDotdotdot($document);
  appExpand($document);

  $document.trigger("app.domset", [{dom: $body}]);

  // run it when the page is already scrolled on refresh so the events based on scrolling can fire at load
  $window.trigger("scroll");

  //

  // initial values
  // update url
  // update content

  var canonicalUrl = $body.find(".js-search-group").data("search-group");

  function slugify(text) {
    return text.toString().toLowerCase().trim()
      .replace(/[łŁ]/g, 'l')
      .replace(/[\/]/g, '-')
      .normalize('NFD') 			   // separate accent from letter
      .replace(/[\u0300-\u036f]/g, '') // remove all separated accents
      .replace(/\s+/g, '-')            // replace spaces with -
      //.replace(/&/g, '-and-')          // replace & with 'and'
      .replace(/[^\w\-]+/g, '')        // remove all non-word chars
      .replace(/\-\-+/g, '-')          // replace multiple '-' with single '-'
  }

  function collectSearch() {
    var slug = slugify($body.find(".js-search-group-input").val());

    if (slug === "") {
      slug = "-";
    }

    return slug;
  }

  function collect(what) {
    var elements = [];

    $body.find(".js-search-group-" + what).find(".js-search-group-select.active").each(function () {
      elements.push($(this).data("search-group-select"));
    });

    if (elements.length === 0) {
      elements.push("-");
    }

    return elements;
  }

  /*function collectTrades() {
    return collect("trades");
  }*/

  function collectCategories() {
    return collect("categories");
  }

  function collectTechnologies() {
    return collect("technologies");
  }

  function collectCities() {
    return collect("cities");
  }

  function update() {
    var data = {
      search: collectSearch(),
      //trades: collectTrades(),
      categories: collectCategories(),
      technologies: collectTechnologies(),
      cities: collectCities()
    };

    var url = canonicalUrl + "/" + data.search + "/" + data.categories.join(",") + "/" + data.technologies.join(",") + "/" + data.cities.join(",");

    history.pushState({}, "", url);

    $.ajax({
      url: url
    }).done(function (response) {
      //console.log(response);
      var $response = $(response);
      $body.find(".js-search-target").replaceWith($response);
      $document.trigger("app.domset", [{dom: $response}]);
    });
  }

  var inputDelay = null;
  $(".js-search-group-input").on("input", function () {
    if (inputDelay) {
      clearTimeout(inputDelay);
    }

    inputDelay = setTimeout(function () {
      update();
    }, 1000);
  });

  $(".js-search-group-list-navigation").on("click", function (event) {
    event.preventDefault();

    var $this = $(this);
    var data = $this.data("search-group-list-navigation");
    var $target = $body.find(data.target);

    $target.stop().animate({
      scrollTop: data.direction === "up" ? "-=70px" : "+=70px"
    });
  });

  $(".js-search-group-select").on("click", function () {
    var $this = $(this);

    $this.toggleClass("active");

    update();
  });
});
