/**
 * Created by Tomasz Kotlarek (ZICHER) on 17.01.2018.
 */

define('application/dotdotdot',["dotdotdot"], function () {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-dotdotdot").each(function () {
        var $this = $(this);

        $this.dotdotdot($.extend({}, $this.data("dotdotdot")));
      });
    });

    return this;
  }
});
