/**
 * Created by Tomasz Kotlarek (ZICHER) on 27.12.2017.
 */

define('application/menu',[],function () {
  return function ($document) {
    $document.on("app.domset", function (event, data) {
      data.dom.find(".js-menu").on("click", function () {
        var $this = $(this);
        var $menu = $document.find($this.data("menu-target"));

        $menu.addClass("active-display");

        setTimeout(function () {
          $menu.toggleClass("active-visibility");
        }, 100);
      });

      data.dom.find(".menu__list__element__link").on("mouseenter", function () {
        var $this = $(this);

        if ($(window).width() >= 992) {
          $(".js-menu-target").find(".menu__list__element__list").css({
            "opacity": 0,
            "pointer-events": "none"
          });

          $this.next().css({
            "opacity": 1,
            "pointer-events": "all",
            "transition-delay": "0s"
          }).one("mouseleave", function () {
            $(this).css({
              "opacity": 0,
              "pointer-events": "none"
            });
          });
        }
      });
    });

    return this;
  }
});
